.App {
  text-align: center;
  transition: all 0.3s ease-in-out;
  min-height: 100vh;
}



/*.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

.App-link {
  color: #61dafb;
}

#darkmode-dot, #darkmode-bg {
  transition: all 0.3s ease-in-out;
}

input:checked ~ #darkmode-dot {
  transform: translateX(135%);
}

input:disabled, textarea:disabled, input:disabled::placeholder, textarea:disabled::placeholder {
  -webkit-text-fill-color: currentcolor; /* 1. sets text fill to current `color` for safari */
  opacity: 0.2; /* 2. correct opacity on iOS */
}

.leaflet-tooltip {
  font-family: MyFont, serif
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext, .tooltipmessage {
  width: 140px;
  top: 100%;
  left: 50%;
  margin-left: -70px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 2px;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 50;
}

.tooltip .tooltiptext {
  visibility: hidden;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
