input[type=range] {
  height: 31px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background: transparent;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #F09642;
  border-radius: 5px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #F09642;
  height: 24px;
  width: 12px;
  border-radius: 6px;
  background: #F09642;
  cursor: grab;
  -webkit-appearance: none;
  margin-top: -10px;
  transition: all 0.3s ease-in-out;
}
input[type=range]::-webkit-slider-thumb:hover {
  transform: scale(1.25);
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #F09642;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #F09642;
  border-radius: 5px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #F09642;
  height: 24px;
  width: 12px;
  border-radius: 6px;
  background: #F09642;
  cursor: grab;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #F09642;
  border: 0px solid #000000;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #F09642;
  border: 0px solid #000000;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #F09642;
  height: 24px;
  width: 12px;
  border-radius: 6px;
  background: #F09642;
  cursor: grab;
}
input[type=range]:focus::-ms-fill-lower {
  background: #F09642;
}
input[type=range]:focus::-ms-fill-upper {
  background: #F09642;
}

#steplist {
  display: flex;
  justify-content: space-between;
  height: 0px;
}

#steplist option {
  background: #F09642;
  width: 4px;
  height: 4px;
  border-radius: 3px;
  margin: 16px 4px 0 4px;
  cursor: pointer;
}