.card:hover > .front {
  transform: rotateY(-180deg);
}

.card:hover > .back {
  transform: rotateY(0deg);
}

.front, .back {
  backface-visibility: hidden;
}

.back {
  transform: rotateY(180deg);
}
