.marginLine {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #fafafa !important; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
} 

.marginLine span { 
   background:#0d1f2d; 
   padding:0 10px; 
}

.min-h-screen {
  min-height: 100vh;
}