.Hotspot{
  /* width: 20px;
  height: 20px; */
  border-radius: 10px;
  border: none;
  background-color: white;
  box-sizing: border-box;
}

.HotspotAnnotation{
  background-color: white;
  position: absolute;
  transform: translate(15px, 15px);
  border-radius: 10px;
  padding: 10px;
}
/* This keeps child nodes hidden while the element loads */
/* :not(:defined) > * {
  display: none;
} */

.skybox > * {
  display: block;
}

a-scene {
  width: 100%;
  height: 100%;
}

model-viewer#reveal {
  --poster-color: transparent;
}


#ar-button {
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  bottom: 16px;
  padding: 0px 16px 0px 16px;
  font-size: 14px;
  color: #0d1f2d;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
}

#ar-button:active {
  background-color: #E8EAED;
}

#ar-button:focus {
  outline: none;
}

#ar-button:focus-visible {
  outline: 1px solid #4285f4;
}

.leaflet-control-layers {
  border: 0px !important;
  border-radius: 20px;
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.4) !important;
  margin: 9px 18px 27px 18px !important;
  background-color: #fafafa;
}
.leaflet-control-layers-toggle {
  width: 40px !important;
  height: 40px !important;
}
.leaflet-control-layers-list {
  width: 96px !important;
  background-color: #fafafa;
  top: -7px;
  left: -7px;
  border-radius: 20px;
  padding: 8px 12px 8px 12px;
  font-family: 'Raleway';
}
.leaflet-control-layers-base {
  text-align: left
}
.leaflet-control-zoom {
  border: 0px !important;
}
.leaflet-control-zoom-in {
  background-color: #fafafa;
  border-radius: 20px !important;
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.4);
  margin: 8px;
  transition: all 0.3s ease-in-out;
  padding-top: 3px;
}
.leaflet-control-zoom-in:hover {
  background-color: #fafafa !important;
  transform: scale(1.1);
}
.leaflet-control-zoom-out {
  background-color: #fafafa;
  border-radius: 20px !important;
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.4);
  margin: 8px;
  transition: all 0.3s ease-in-out;
  padding-top: 3px;
}
.leaflet-control-zoom-out:hover {
  background-color: #fafafa !important;
  transform: scale(1.1);
}